import React from "react";

const Header = () => {
  return (
    <div style={{ display: "flex", justifyContent:"center"}}>
      <h1 >Form Builder</h1>
    </div>
  );
};

export default Header;
